<template>

  <div>
    
    <Header title="Bookshelves"></Header>

    <v-list two-line v-if="bookshelves.length">

      <div v-for="(bookshelves,letter) in groupedBookshelves" 
           :key="letter">

        <v-subheader>
          {{ letter }}
        </v-subheader>
      
        <v-list-item 
          v-for="bookshelf in bookshelves" 
          :key="bookshelf.id"
          @click="goTo(bookshelf)">

          <v-list-item-content>
            <v-list-item-title>
              {{ bookshelf.name.toUpperCase() }}
              <v-chip class="ml-2" small 
                      v-html="bookshelf.books_count"></v-chip>
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>

      </div>

    </v-list>

    <v-alert text color="info" v-else>
      <h3 class="headline">No bookshelves here (yet!)</h3>
    </v-alert>

    <BookshelfModal :button="false"
                    ref="bookshelfModal"
                    @save="onModalSave"/>

    <ActionButton>
      <v-btn fab dark @click="openBookshelfModal">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </ActionButton>

  </div>

</template>

<script>

  import _ from 'lodash'
  import Api from '../api'

  import ActionButton from '../components/ActionButton'
  import BookshelfModal from '../components/bookshelf/Modal'
  import Header from '../components/Header'

  export default {
    name: 'Bookshelves',

    components: {
      ActionButton,
      BookshelfModal,
      Header
    },

    mounted(){
      this.loadBookshelves()
    },

    methods: {

      goTo(bookshelf)
      {
        this.$router.push({ name: 'bookshelf', params: { bookshelfId: bookshelf.id } })
      },

      /**
       * Load bookshelves to list via API.
       */
      loadBookshelves()
      {
        this.$store.commit('setLoading')

        Api.bookshelves.list().then((res)=>{
          // res.data.links
          this.bookshelves = res.data.data

          this.$store.commit('setLoading', false)
        })
      },

      onModalSave()
      {
        this.loadBookshelves()
      },

      openBookshelfModal()
      {
        this.$refs.bookshelfModal.show()
      }

    },

    data: () => ({
      bookshelves: []
    }),

    computed: {
      groupedBookshelves()
      {
        return _.groupBy(this.bookshelves, (bookshelf)=> {
          return bookshelf.name.toUpperCase().substring(0,1)
        })
      }
    }
  }
</script>
