import _ from 'lodash'
import store from '../store'

const favourites = {
  
    created: function () {},

    mounted(){
        
    },

    data: () => ({
        
    }),
  
    computed: {},

    methods: {
        
        isFavQuote(quote)
        {
            var favs = store.getters.favourites.quotes,
                check = _.chain(favs)
                        .filter({id:quote.id} )
                        .isEmpty()
                        .value()
            return !check
        },

    }
  
}

export default favourites