<template>

  <div>

    <Header>
      Authors
    </Header>

    <v-container fluid>
      
      <v-list two-line subheader v-for="group in authors" :key="group.letter">
      
        <v-subheader inset>
          {{ group.letter }}
        </v-subheader>

        <v-list-item 
          v-for="author in group.authors" 
          :key="author.id"
          @click="goTo(author)">

          <v-list-item-avatar>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-avatar>
          
          <v-list-item-content>
            <v-list-item-title v-text="author.fullname"></v-list-item-title>
            <v-list-item-subtitle v-text="''"></v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>

        <v-divider inset></v-divider>

      </v-list>

    </v-container>

  </div>

</template>

<script>

  import _ from 'lodash'
  import Api from '../api'

  import Header from '../components/Header'

  export default {
    name: 'Authors',

    components: {
      Header
    },

    mounted(){
      this.loadAuthors()
    },

    methods: {

      goTo(author)
      {
        this.$router.push({ name: 'author', params: { authorId: author.id } })
      },

      /**
       * Load books to list via API.
       */
      loadAuthors()
      {
        this.$store.commit('setLoading')

        Api.authors.list().then((res)=>{
          this.authors = _(res.data.data)
                        .groupBy(o => {
                          let l = o.fullname.split(' ').pop().toUpperCase()[0]
                          return l
                        })
                        .map((authors, letter) => ({ letter, authors }))
                        .sortBy(g => g.letter)
                        .value()

          this.$store.commit('setLoading', false)
        })
      }

    },

    data: () => ({
      authors: {}
    }),
  }
</script>
