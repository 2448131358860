<template>

  <div>

    <Header>
      <template>
        <v-chip class="ma-2" dark v-html="bookshelf.books_count"></v-chip>
        {{ bookshelf.name }} 
      </template>
    </Header>

    <v-container fluid>
      
      <v-row align="start" v-if="books.length">
      
        <v-col cols="6" md="2" v-for="(book) in bookshelf.books.data" :key="book.id">
      
          <BookCard :book="book" 
                    @click="goTo"
                    @contextmenu="onContextMenu"/>
      
        </v-col>
      
      </v-row>

      <Message v-else title="This bookshelf is empty."/>

    </v-container>

    <BookshelfModal :bookshelf-id="bookshelf.id" 
                    :button="false"
                    ref="bookshelfModal"
                    @save="onModalSave"
                    @delete="onModalDelete"/>

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

    <ActionButton>
      <v-btn fab dark @click="openBookshelfModal">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </ActionButton>

  </div>

</template>

<script>

  import Api from '../api'

  import ActionButton from '../components/ActionButton'
  import BookContextMenu from '@/components/book/context-menu/Menu'
  import BookshelfModal from '../components/bookshelf/Modal'
  import Header from '../components/Header'
  import Message from '../components/Message'

  export default {
    name: 'Bookshelf',

    components: {
      ActionButton,
      BookContextMenu,
      BookshelfModal,
      Header,
      Message
    },

    mounted()
    {
      this.loadBookshelf()
    },

    methods: {

      goTo(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      },

      /**
       * Load books to list via API.
       */
      loadBookshelf()
      {
        let bookshelfId = this.$route.params.bookshelfId,
            params = { with:['bookshelves'] }

        this.$store.commit('setLoading')

        Api.bookshelves.one(bookshelfId, params).then((res)=>{
          // res.data.links
          this.bookshelf = res.data.data

          this.$store.commit('setLoading', false)
        })
      },

      /**
       * Open book context menu when book card is right-clicked.
       */
      onContextMenu(e, book)
      {
        this.$refs.contextMenu.show(e, book)
      },

      /**
       * When bookshelf is deleted in modal.
       */
      onModalDelete()
      {

        this.$router.push({ name: 'bookshelves' })
      },

      /**
       * When bookshelf is updated in modal.
       */
      onModalSave()
      {
        this.loadBookshelf()
      },

      openBookshelfModal()
      {
        this.$refs.bookshelfModal.show()
      }

    },

    data: () => ({
      bookshelf: {}
    }),

    computed: {
      books()
      {
        return this.bookshelf.books.data || []
      }
    }
  }
</script>
