import _ from 'lodash'
import SearchResult from '@/classes/searchResult'

const searchMixin = {
  methods: {
    
    mapSearchResults(resData={})
    {
      let results = []

      if(resData.books)
        results = results.concat( _.map(resData.books, (result)=>(new SearchResult()).fromBook(result) ) )
      
      if(resData.quotes)
        results = results.concat( _.map(resData.quotes, (result)=>(new SearchResult()).fromQuote(result) ) )
      
        if(resData.topics)
        results = results.concat( _.map(resData.topics, (result)=>(new SearchResult()).fromTopic(result) ) )
      
      return results
    },

    openSearchResult(result, popup=false)
    {
      let modelType, modelId, hash = '', route={}

      if(result.owner) {
        modelType = result.owner.type
        modelId = result.owner.id
        hash = `#${result.model}-${result.value}`
      }
      else {
        modelType = result.model
        modelId = result.value        
      }

      switch(modelType)
      {
        case 'book':
        case 'quote':
          route = { name: 'book', params: { bookId: modelId }, hash }
          break;
        case 'author':
          route = { name: 'author', params: { authorId: modelId }, hash }
          break;
        case 'bookshelf':
          route = { name: 'bookshelf', params: { bookshelfId: modelId }, hash }
          break;
        case 'genre':
          route = { name: 'genre', params: { genreId: modelId }, hash }
          break;
        case 'tag':
          route = { name: 'tag', params: { tagId: modelId }, hash }
          break;
        case 'topic':
          route = { name: 'topic', params: { topicId: modelId }, hash }
          break;
      }

      if( popup ) {
        const routeUrl = this.$router.resolve(route).href
        window.open(routeUrl, '_blank')
      }
      else {
        this.$router.push(route)
      }
    }
    
  }
}

export default searchMixin