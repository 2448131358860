const utility = {
  methods: {
    
    toColour(str)
    {
      var hash = 0;
      
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      
      var colour = '#';
      
      for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      
      return colour;
    },

    copyToClipboard(content)
    {
      navigator.clipboard.writeText(content).then(() => {
        console.log("Text copied to clipboard")
      }).catch(err => {
        console.error("Failed to copy text: ", err)
      })
    },

    stripHtml(html)
    {
      let doc = new DOMParser().parseFromString(html, 'text/html')
      return doc.body.textContent || ""
    }

  }
}

export default utility