<template>

  <v-container fluid>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn-toggle v-model="status" color="primary">
          <v-btn value="reading" class="px-5">
            <v-badge dot 
                     class="mr-3" 
                     style="margin-top:3px"></v-badge>
            READING
          </v-btn>

          <v-btn value="read" class="px-5">
            <v-badge dot color="success" 
                     class="mr-3" 
                     style="margin-top:3px"></v-badge>
            READ
          </v-btn>

          <v-btn value="to-read" class="px-5">
            <v-badge dot color="grey darken-2" 
                     class="mr-3" 
                     style="margin-top:3px"></v-badge>
            TO READ
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row align="start" v-if="loading && !books.length">
      <v-col cols="6" md="2" v-for="i in 6" :key="i">
        <v-sheet
          color="grey lighten-4"
          class="pa-3"
        >
          <v-skeleton-loader type="card" class="w-100"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>

    <Message v-if="!loading && !books.length">
      No books here.
    </Message>
    
    <v-row align="start">
    
      <v-col cols="6" md="2" v-for="(book) in books" :key="book.id">
    
        <BookCard :book="book" 
                  @click="goTo"
                  @contextmenu="onContextMenu"/>
    
      </v-col>
    
    </v-row>

    <ActionButton icon="mdi-plus">
      <v-btn fab dark @click="openBookScanner"
                      v-shortkey="['i']" 
                      @shortkey="openBookScanner">
        <v-icon>mdi-barcode-scan</v-icon>
      </v-btn>
      <v-btn fab dark @click="openBookSearch"
                      v-shortkey="['s']" 
                      @shortkey="openBookSearch">
        <v-icon>mdi-book-search</v-icon>
      </v-btn>
      <v-btn fab dark @click="openScannerBulk">
        <v-icon>mdi-barcode</v-icon>
      </v-btn>
    </ActionButton>

    <BookScanner ref="bookScanner"/>
    <ScannerBulk ref="scannerBulk"/>

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

    <!-- keyboard shortcuts -->
    <button class="d-none"
            v-shortkey="['ctrl','i']" 
            @shortkey="openBookScanner"></button>
    <button class="d-none"
            v-shortkey="['ctrl','s']" 
            @shortkey="openBookSearch"></button>
    <button class="d-none"
            v-shortkey="['ctrl','m']" 
            @shortkey="openScannerBulk"></button>

  </v-container>

</template>

<script>

  import Api from '../api'

  import InfiniteScroll from '../mixins/infinite-scroll'

  import ActionButton from '../components/ActionButton'
  import BookContextMenu from '@/components/book/context-menu/Menu'
  import BookScanner from '../components/book/Scanner'
  import ScannerBulk from '../components/scanner-bulk/Scanner'
  import Message from '../components/Message'

  export default {
    name: 'Books',

    components: {
      ActionButton,
      BookContextMenu,
      BookScanner,
      ScannerBulk,
      Message
    },

    mixins: [InfiniteScroll],

    mounted(){
      this.loadBooks()
    },

    methods: {

      goTo(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      },

      /**
       * Load books to list via API.
       */
      loadBooks()
      {
        this.loading = true

        var page = (this.meta.current_page + 1)

        Api.books.list({ 
          page: page, 
          per_page: 18,
          status: this.status,
          with: ['bookshelves']
        }).then((res)=>{
          
          this.books = this.books.concat(res.data.data)
          
          this.meta = res.data.meta

          this.loading = false

        })
      },

      onContextMenu(e, book)
      {
        this.$refs.contextMenu.show(e, book)
      },

      openBookScanner()
      {
        this.$refs.bookScanner.show('scanner')
      },

      openBookSearch()
      {
        this.$refs.bookScanner.show('search')
      },

      openScannerBulk()
      {
        this.$refs.scannerBulk.show()
      },

      reset()
      {
        this.books = []
        
        this.meta = { current_page:0, last_page:0 }
      }

    },

    data: () => ({
      books: [],
      loading: false,
      meta: {
        current_page: 0,
        last_page: 0
      },
      status: ''
    }),

    computed: {
      hasMore()
      {
        return (this.meta.current_page < this.meta.last_page);
      }
    },

    watch: {
      infiniteScroll: {
         handler(infiniteScroll){
          if (infiniteScroll.bottom && this.hasMore)
            this.loadBooks()
         },
         deep: true
      },
      loading(status)
      {
        this.$store.commit('setLoading', status)
      },
      status()
      {
        this.reset()

        this.loadBooks()
      }
    }
  }
</script>
