<template>

  <div>

    <template v-if="book">
      
      <BookRow :book="book"/>

      <v-divider class="mt-5"/>

      <div class="grey lighten-4 pt-5">

        <v-container>

          <!-- Book rating. -->
          <div v-if="book.id">
            <span class="text-h5">Rating</span>
            <v-rating
              color="warning"
              length="5"
              size="32"
              :value="book.rating"
              @input="updateRating"
            ></v-rating>
          </div>

          <v-skeleton-loader type="paragraph" v-else />

          <!-- Book genres. -->
          <Genres :book="book"
                  @click="openGenresModal"
                  readonly
                  only-selected
                  class="my-6"
                  v-if="book.genres">
            <template v-slot:empty>
              <v-btn icon
                     variant="tonal">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </Genres>
          
          <v-skeleton-loader type="paragraph" v-else />

          <!-- Bookshelves. -->
          <Bookshelves :book="book" 
                       @toggle="loadBook"
                       class="my-6"
                       v-if="book.bookshelves"/>

          <v-skeleton-loader type="paragraph" v-else />

          <!-- Book tags. -->
          <div class="my-6" v-if="book.tags">
            <span class="text-h5">Tags</span>
            <Tags model-type="book" 
                  :model-id="book.id"
                  only-active>
              <v-btn @click="$refs.tagsModal.show()"
                     icon
                     variant="tonal">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </Tags>
          </div>

          <v-skeleton-loader type="paragraph" v-else />

        </v-container>

      </div>

    </template>

    <BookTabs :book="book" ref="bookTabs"/>

    <!-- scroll to bottom -->
    <v-speed-dial
      bottom
      right fixed
      class="hidden-print-only">
      <template v-slot:activator>
        <v-btn
          dark
          fab
          style="margin-top:-170px"
          @click="scrollToEnd">
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <ActionButton>
      <v-btn fab dark @click="openBookModal">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark @click="openQuoteModal">
        <v-icon>mdi-bookmark</v-icon>
      </v-btn>
      <v-btn fab dark @click="openNoteModal">
        <v-icon>mdi-note</v-icon>
      </v-btn>
      <v-btn fab dark @click="openBookScanner('scanner')">
        <v-icon>mdi-barcode-scan</v-icon>
      </v-btn>
      <v-btn fab dark @click="openBookScanner('search')">
        <v-icon>mdi-book-search</v-icon>
      </v-btn>
      <NetworkActionButton :model-id="book.id"
                           model-type="book"/>
      <BookScanner />
      <v-btn fab dark @click="openSearchCoverModal">
        <v-icon>mdi-image-search-outline</v-icon>
      </v-btn>
    </ActionButton>

    <GenresModal ref="genresModal"
                 :book="book"
                 @toggle="loadBook"
                 button-class="d-none"/>

    <QuoteModal @save="onNewQuote"
                ref="quoteModal"
                :owner-id="book.id"
                owner-type="book"
                button-class="d-none"/>

    <NoteModal @save="onNewNote"
                ref="noteModal"
                :owner-id="book.id"
                owner-type="book"
                button-class="d-none"/>

    <BookModal :book-id="book.id" 
               button-class="d-none"
               ref="bookModal"
               @save="onBookSave"/>

    <TagsModal model-type="book"
               :model-id="book.id"
               ref="tagsModal"/>

    <BookScanner ref="bookScanner"/>

    <SearchCoverModal ref="searchCoverModal" 
                      :book="book"/>

    <button class="d-none"
            v-shortkey="['ctrl','i']" 
            @shortkey="openBookScanner('scanner')"></button>
    <button class="d-none"
            v-shortkey="['ctrl','s']" 
            @shortkey="openBookScanner('search')"></button>

  </div>

</template>

<script>

  import Api from '../api'

  import ActionButton from '../components/ActionButton'
  import BookModal from '../components/book/Modal'
  import BookRow from '../components/book/Row'
  import BookScanner from '../components/book/Scanner'
  import Bookshelves from '../components/bookshelf/Bookshelves'
  import BookTabs from '../components/book/Tabs'
  import Genres from '../components/genre/Genres'
  import GenresModal from '../components/genre/Modal'
  import NetworkActionButton from '../components/network/ActionButton'
  import NoteModal from '../components/note/Modal'
  import QuoteModal from '../components/quote/Modal'
  import SearchCoverModal from '../components/search-cover/Modal'
  import Tags from '../components/tag/Tags'
  import TagsModal from '../components/tag/TagsModal.vue'

  export default {
    name: 'Book',

    components: {
      ActionButton,
      BookModal,
      BookRow,
      Bookshelves,
      BookScanner,
      BookTabs,
      Genres,
      GenresModal,
      NetworkActionButton,
      NoteModal,
      QuoteModal,
      SearchCoverModal,
      Tags,
      TagsModal
    },

    mounted(){
      this.loadBook()
    },

    methods: {

      /**
       * Load book via API.
       */
      loadBook()
      {
        let bookId = this.$route.params.bookId

        this.$store.commit('setLoading')

        const params = {
          with: [
            'bookshelves',
            'topics_extended_count'
          ]
        }

        Api.books.one(bookId, params).then((res)=>{
          // res.data.links
          this.book = res.data.data

          // set page title
          document.title = this.book.title

          this.$store.commit('setLoading', false)
        })
      },

      onBookSave(updatedBook)
      {
        this.book = updatedBook
      },

      onNewNote()
      {
        this.$refs.bookTabs.fetchNotes()
      },

      onNewQuote()
      {
        this.$refs.bookTabs.book.quotes_count++
        this.$refs.bookTabs.fetchQuotes()
      },

      openBookModal()
      {
        this.$refs.bookModal.show()
      },

      openBookScanner(mode)
      {
        this.$refs.bookScanner.show(mode)
      },

      openGenresModal()
      {
        this.$refs.genresModal.show()
      },

      openNoteModal()
      {
        this.$refs.noteModal.show()
      },

      openQuoteModal()
      {
        this.$refs.quoteModal.show()
      },

      openSearchCoverModal()
      {
        this.$refs.searchCoverModal.show()
      },

      scrollToEnd()
      {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        })
      },

      updateRating(rating)
      {
        Api.books.update(this.book.id, { rating }).then((res)=>{
          console.log(res.data)
        })
      },

    },

    data: () => ({
      book: {},
      bookScannerMode: 'scanner'
    }),

    watch: {
      $route() {
        this.loadBook()
      }
    }
  }
</script>
