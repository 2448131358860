const bookContextSubmenu = {
  
    created () {},

    mounted () {},

    props: {
      book: {
        type: Object
      }
    },

    data: () => ({
        isLoading: false
    }),
  
    computed: {
        
    },

    methods: {
        back()
        {
            this.$emit('back')
        }
    }
  
}

export default bookContextSubmenu