<template>

  <div>

    <Header :title="author.fullname" />

    <v-container fluid>
      
      <v-row align="start">
      
        <v-col cols="6" md="2" v-for="(book) in books" :key="book.id">
      
          <BookCard :book="book" 
                    @click="goTo"
                    @contextmenu="onContextMenu"/>
      
        </v-col>
      
      </v-row>

    </v-container>

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

  </div>

</template>

<script>

  import Api from '@/api'
  import BookContextMenu from '@/components/book/context-menu/Menu'
  import Header from '@/components/Header'

  export default {
    name: 'Author',

    components: {
      BookContextMenu,
      Header
    },

    mounted(){
      this.loadAuthor()
      this.loadBooks()
    },

    methods: {

      goTo(author)
      {
        this.$router.push({ name: 'book', params: { bookId: author.id } })
      },

      loadAuthor()
      {
        let authorId = this.$route.params.authorId

        Api.authors.one(authorId).then((res)=>{
          
          this.author = res.data.data

          // set page title
          document.title = this.author.fullname
        })
      },

      /**
       * Load books to list via API.
       */
      loadBooks()
      {
        let authorId = this.$route.params.authorId

        Api.books.list({ authorId:authorId, with:['bookshelves'] })
                 .then((res) => {
                  this.books = res.data.data
                 })
      },

      onContextMenu(e, book)
      {
        this.$refs.contextMenu.show(e, book)
      },

    },

    data: () => ({
      author: {},
      books: []
    }),
  }
</script>
