import _ from 'lodash'
import Api from '../api'

const favouriteToggle = {
  
    created: function () {},

    mounted(){
        this.loadFavourites()
    },

    data: () => ({
        favouriteToggle: {
            isFav: false,
            modelId: null,
            modelType: ''
        }
    }),
  
    computed: {},

    methods: {
        loadFavourites()
        {
            Api.favourites.list()
               .then((res)=>{

                var modelId = parseInt(this.favouriteToggle.modelId),
                    modelType = this.favouriteToggle.modelType

                if( !modelId || !modelType )
                    return

                var model = _.chain(res.data[`${modelType}s`])
                            .filter({ id:modelId })
                            .value()

                this.favouriteToggle.isFav = !_.isEmpty(model)
            })
        },
    }
  
}

export default favouriteToggle