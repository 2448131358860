<template>

  <div v-if="isLoaded">

    <v-toolbar color="grey lighten-3">
      <div v-if="article.owner">
        <p v-if="isFromMagazine" class="mb-0">
          <router-link :to="{ name:'magazine', params: { magazineId:magazine.id } }">
            {{ magazine.title }}
          </router-link>
        </p>
        <router-link :to="parentRoute">
          <strong class="text--primary">{{ article.owner.title | uppercase }}</strong>
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon v-if="article.url"
          @click.stop="open(article.url)">
        <v-icon size="30">mdi-link</v-icon>
      </v-btn>

      <v-btn icon v-if="article.pdf"
          @click.stop="open(article.pdf)">
        <v-icon size="30">mdi-file-pdf-box</v-icon>
      </v-btn>

      <FavouriteButton :status="favouriteToggle.isFav"
                       model-type="article" 
                       :model-id="article.id" 
                       @toggled="onFavToggle"
                       v-if="article.id"/>
    </v-toolbar>

    <v-container fluid>
      
      <v-chip
        class="ma-2"
        color="primary"
        label
        v-for="(author,i) in article.authors" 
        :key="`author-${i}`"
        @click="goToAuthor(author)"
      >
        <v-icon left>mdi-account-circle-outline</v-icon>
        {{ author.fullname }}
      </v-chip>
      
      <h1 class="my-5">
        {{ article.title }}
      </h1>

      <div v-if="article.description"
           v-html="article.description"></div>

    </v-container>

    <ArticleTabs :article="article" 
                 ref="articleTabs"
                 class="mt-3"/>

    <ActionButton>
      <v-btn fab dark @click="openArticleModal">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark @click="openNoteModal">
        <v-icon>mdi-note</v-icon>
      </v-btn>
      <v-btn fab dark @click="openQuoteModal">
        <v-icon>mdi-bookmark</v-icon>
      </v-btn>
      <v-btn dark fab @click="openDeleteModal">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
      <NetworkActionButton :model-id="article.id"
                           model-type="article"/>
    </ActionButton>

    <ArticleModal :article="article"
                  :owner-id="article.owner_id" 
                  :owner-type="article.owner_type"
                  ref="articleModal"
                  @save="onArticleSave"/>

    <NoteModal @save="onNewNote"
                ref="noteModal"
                :owner-id="article.id"
                owner-type="article"
                button-class="d-none"/>

    <QuoteModal @save="onNewQuote"
                ref="quoteModal"
                :owner-id="article.id"
                owner-type="article"
                button-class="d-none"/>

    <DeleteModal ref="deleteModal"
                 :article="article"
                 :auto-close="false"
                 @delete="onDelete"/>

  </div>

</template>

<script>

  import _ from 'lodash'

  import Api from '../api'
  import Utility from '../utils'

  import ActionButton from '../components/ActionButton'
  import ArticleModal from '../components/article/Modal'
  import ArticleTabs from '../components/article/Tabs'
  import DeleteModal from '../components/article/DeleteModal'
  import FavouriteButton from '../components/FavouriteButton'
  import NetworkActionButton from '../components/network/ActionButton'
  import NoteModal from '../components/note/Modal'
  import QuoteModal from '../components/quote/Modal'

  import FavouriteToggle from '../mixins/favourite-toggle'

  export default {
    name: 'Article',

    components: {
      ActionButton,
      ArticleModal,
      ArticleTabs,
      DeleteModal,
      FavouriteButton,
      NetworkActionButton,
      NoteModal,
      QuoteModal
    },

    mixins: [FavouriteToggle],

    mounted(){
      this.loadArticle()

      this.favouriteToggle.modelType = 'article'
    },

    methods: {

      isFrom(model)
      {
        if( _.isEmpty(this.article) ||
            _.isEmpty(this.article.owner_type) )
          return false
        
        let ownerType = Utility.methods.getModelType(this.article.owner_type)

        if(!ownerType)
          return false

        return ownerType.name === model
      },

      /**
       * Load article via API.
       */
      loadArticle()
      {
        let articleId = this.$route.params.articleId

        this.favouriteToggle.modelId = articleId

        this.$store.commit('setLoading')

        Api.articles.one(articleId).then((res)=>{
          
          let article = res.data.data
          
          this.article = article

          // set page title
          document.title = this.article.title

          // article is from magazine or website?
          if( this.article.owner && 
              this.isFromMagazine && 
              this.article.owner.magazine_id )
            this.loadMagazine( article.owner.magazine_id )

          this.$store.commit('setLoading', false)
        })
      },

      loadMagazine(magazineId)
      {
        this.magazine = {}

        Api.magazines.one(magazineId)
                     .then((res)=>{
                      this.magazine = res.data.data
                     })
      },

      onArticleSave(article)
      {
        this.article = article
      },

      onDelete()
      {
        window.setTimeout(()=>{
          this.$router.push(this.parentRoute)
        }, 1500)
      },

      onFavToggle()
      {
        this.loadFavourites()
      },

      onNewNote()
      {
        this.$refs.articleTabs.fetchNotes()
      },

      onNewQuote()
      {
        this.$refs.articleTabs.article.quotes_count++
        this.$refs.articleTabs.fetchQuotes()
      },

      open(url)
      {
        window.open(url)
      },

      openArticleModal()
      {
        this.$refs.articleModal.show()
      },

      openDeleteModal()
      {
        this.$refs.deleteModal.show()
      },

      openNoteModal()
      {
        this.$refs.noteModal.show()
      },

      openQuoteModal()
      {
        this.$refs.quoteModal.show()
      },

    },

    data: () => ({
      article: {},
      magazine: {}
    }),

    computed: {
      isFromMagazine()
      {
        return this.isFrom('issue')
      },
      isFromWebsite()
      {
        return this.isFrom('website')
      },
      isLoaded()
      {
        return !_.isEmpty(this.article)
      },
      parentRoute()
      {
        if( this.isFromMagazine )
          return { 
            name:'issue', 
            params: { issueId:this.article.owner.id } 
          }

        else if( this.isFromWebsite )
          return { 
            name:'website', 
            params: { websiteId:this.article.owner_id } 
          }

        return {}
      }
    }
  }
</script>
