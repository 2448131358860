const infiniteScroll = {
  
    created: function () {},

    mounted(){
        window.addEventListener('scroll', () => {
            this.infiniteScroll.bottom = this.infiniteScrollBottomVisible()
        })
    },

    data: () => ({
        infiniteScroll: {
            bottom: false
        }
    }),
  
    computed: {},

    methods: {
        infiniteScrollBottomVisible()
        {
            const   scrollY = window.scrollY,
                    visible = document.documentElement.clientHeight,
                    pageHeight = document.documentElement.scrollHeight,
                    bottomOfPage = visible + scrollY >= pageHeight
            
            return bottomOfPage || pageHeight < visible
        },
    }
  
}

export default infiniteScroll